import { Component, OnInit, ViewChild, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MultiSelect } from 'primeng/multiselect';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';

import { getCypressFieldName } from '../../helpers';
import { LabelValueWithInfo } from '../ez-form-control-dropdown/label-value-with-info-hover.model';
import { EzFormControlTemplateError } from '../ez-form-control-template/ez-form-control-template-error';
import { EzFormControl } from '../../ez-form-controls';

@UntilDestroy()
@Component({
  selector: 'ez-form-control-dropdown-multiselect',
  templateUrl: './ez-form-control-dropdown-multiselect.component.html',
  styleUrls: ['./ez-form-control-dropdown-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlDropdownMultiselectComponent),
      multi: true
    }
  ]
})
export class EzFormControlDropdownMultiselectComponent implements ControlValueAccessor, OnInit {

  @ViewChild('dropdownMultiselect', { static: false }) dropdown: MultiSelect;
  @ViewChild('container') containerRef: ElementRef;

  @Input() isDisabled: boolean = false;
  @Input() keyField: string;
  @Input() simplePlaceholder: string = ResourcesProvider.rks.DropdownSelectPlaceholder();

  _control: AbstractControl;
  @Input() set control(absControl: AbstractControl) {
    this._control = absControl;
    let _fc = absControl as EzFormControl;
    if (_fc) {
      this.controlLabel = _fc.controlLabel;
    };
  };


  _showDropDown = true;
  @Input() set showDropDown(show: boolean) {
    this._showDropDown = show;
    this.showDropDownChange.emit(show);
  }

  _dropDownPlaceholderText = '';
  @Input() set dropDownPlaceholderText(text: string) {
    this._dropDownPlaceholderText = text;
  }

  _dropDownLinkText = '';
  @Input() set dropDownLinkText(text: string) {
    this._dropDownLinkText = text;
  }

  _searchPlaceHolder: string = 'Search';
  @Input() set searchPlaceHolder(text: string) {
    this._searchPlaceHolder = text;
  }

  _boldLabel: boolean = false;
  @Input() set boldLabel(bold: boolean) {
    this._boldLabel = bold;
  }

  _options: LabelValueWithInfo[] = [];
  @Input() set options(opts: LabelValueWithInfo[]) {
    this._options = opts;
  }

  @Input() toolTipHtml: string = null;
  @Input() emptyMessage: string = ResourcesProvider.rks.NoRecordsFound;
  @Input() emptyFilterMessage: string = ResourcesProvider.rks.NoOptionsAvailablePleaseSelectAnotherFilter;
  @Input() customValidationMessages: Record<string, string> | null = null;
  @Input() showBottomMargin: boolean = true;
  @Input() selectedValue: any;

  @Input() set isMobileDevice(isMobile: boolean) {
    this.isMobileDevice$.next(isMobile);
  };

  @Output() showDropDownChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() optionSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  _placeholderVisible = true;
  cypressName: string = '';

  searchTerm: BehaviorSubject<string> = new BehaviorSubject('');
  searchTerm$: Observable<string> = this.searchTerm.asObservable();

  overflowCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  isMobileDevice$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  controlLabel: string = '';
  showControlLabelOnOpenedDropdown: boolean = false;

  customFilter: string = '';

  customFilterControl = new FormControl();

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this._control);
  }

  ngAfterViewInit() {
    this.customFilterControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.dropdown.filterValue = value;
        this.searchTerm.next(value);

        setTimeout(() => {
          this.dropdown.onFilter.emit({
            originalEvent: null,
            filter: value
          });
        }, 1);
      });
    this.onSelectedItemsChange();
  }

  writeValue(value: any): void {
    this.setValue(value);
    this.onChange(this.selectedValue);
    this.cdr.markForCheck();
  }

  dropdownChange(value) {
    this.writeValue(value);
    this.onTouched();
    this.optionSelected.emit(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setValue(value: any) {
    if (this._options?.length > 0) {
      if (this.keyField && value && typeof value === 'object') {
        this.selectedValue = this._options.find(lv => lv.value[this.keyField] === value[this.keyField])?.value;
      } else if (this.keyField && value) {
        this.selectedValue = this._options.find(lv => lv.value === value)?.value;
      } else {
        this.selectedValue = value;
      }
    }
  }

  onSelectedItemsChange() {
    const container = this.containerRef?.nativeElement;
    const input = container.querySelector('.p-multiselect-label');

    const mutationCallback = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const content = input.innerText;
          const availableSpace = input.offsetWidth;

          const fontSize = "10px";
          const fontFamily = "Fira Sans";
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          context.font = `${fontSize} ${fontFamily}`;
          const selectedOptions = content.split('\n');
          let space = availableSpace;
          this.overflowCount$.next(0);
          for (let i = 0; i < selectedOptions.length; i++) {
            const option = context.measureText(selectedOptions[i]).width + 17;
            space = space - option;

            if (space < 0) {
              this.overflowCount$.next(selectedOptions.length - i++);
              break;
            }
          }
        }
      });
    };

    const observer = new MutationObserver(mutationCallback);
    const observerConfig = {
      attributes: true,
      childList: true
    };
    observer.observe(input, observerConfig);
  }

  applyCustomFilter() {
    this.customFilterControl.setValue(this.customFilter);
  }

  clearFilter() {
    this.customFilter = '';
    this.customFilterControl.setValue(' ');
    //don`t change ' ' to '' before primeng new version apply; then check if option panel will be close on clear.
  }

  isAllSelected(): boolean {
    return this.selectedValue?.length === this._options?.length;
  }

  selectAll(event: any): void {
    if (event.target.checked) {
      this.selectedValue = this._options.map((option) => option.value);
    } else {
      this.selectedValue = [];
    }
    this.dropdownChange(this.selectedValue);
  }

  toggleControlLabelAppearanceOnMobile() {
    this.showControlLabelOnOpenedDropdown = !this.showControlLabelOnOpenedDropdown;
  }

  onPanelHide() {
    this.clearFilter();
    this.toggleControlLabelAppearanceOnMobile();
    this.closeEvent.emit(this.selectedValue);
  }

  inputIsInvalid(event: EzFormControlTemplateError) {
    if (event.hasError) {
      this._control.markAsTouched();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
