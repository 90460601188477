import { AbstractControl, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { ResourcesProvider } from '@ezuiaws/ez-packages/ez-localization';
import { EzNotificationService } from '@ezuiaws/ez-packages/ez-notification';
import { NotificationType } from '@ezuiaws/ez.ui.models';

import { EzFormControl } from '../../ez-form-controls';
import { EzAsyncValidator } from '../../validators';

import { EzFormControlTemplateError } from './ez-form-control-template-error';
import { ezValidationMessages } from './ez-validation-messages';

@UntilDestroy()
@Component({
  selector: 'ez-form-control-template',
  templateUrl: './ez-form-control-template.component.html',
  styleUrls: ['./ez-form-control-template.component.scss']
})
export class EzFormControlTemplateComponent implements OnInit {

  @ViewChild('panelTipContent', { static: true }) content: ElementRef;

  @Input() fieldName: string = '';
  @Input() validationCompareFieldName: string;
  @Input() validate: boolean = true;
  @Input() showLabel: boolean = true;
  @Input() isPercent: boolean = false;
  @Input() visible: boolean = true;
  @Input() disabled: boolean = false;
  @Input() toolTipHtml: InnerHTML = null;
  @Input() useTemplateValidationOutput: boolean = true;
  @Input() boldLabel: boolean = false;
  @Input() customValidationMessages: Record<string, string> | null = null;
  @Input() customErrorFieldLabel: string;
  @Input() isTextArea: boolean = false;
  @Input() lighteningLabelOnDisabled: boolean = true;
  @Input() horizontalAlignment: boolean = false;
  @Input() showWarningMessage: boolean = false;
  @Input() warningMessageText: string = "";

  @Input() showBottomMargin: boolean = true;
  @Input() showTopMargin: boolean = true;

  /*
  Provide control errors separately of control, to avoid timing issue with FormGroup validation
   */
  @Input() set controlErrors(errors: Record<string, boolean> | null) {
    let outputMessage = '';
    let hasError: boolean = false;

    if (errors) {
      hasError = true;
      outputMessage += this.buildValidationErrorMessage(errors);
    }
    this.isInvalid.emit({ hasError: hasError, errorMessage: outputMessage });
    this._controlMessage = outputMessage;
  }

  isRequired = false;
  _fc: AbstractControl;

  @Input() set control(fc: AbstractControl | FormGroup) {
    this._fc = fc;
    if (fc instanceof FormGroup) {
      this.cypressName = fc.parent?.value?.formName || this.fieldName;
    } else {
      let _fc = fc as EzFormControl;
      if (_fc) {
        this.fieldLabel = _fc.controlLabel;
        this.fieldName = this.fieldName || _fc.fieldName;

        this.cypressName = _fc.fieldName;

        if (_fc.validatorList
          && Array.isArray(_fc.validatorList)
          && _fc.validatorList.filter(x => x.name.toLowerCase().indexOf('required') >= 0).length > 0) {
          this.isRequired = true;
        }
      }
    }

    if (this.validate && fc) {
      this._fc.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(_ => {
          if (this._fc.touched) {
            this._controlMessage = this.setMessage(this._fc);
          } else {
            // resets validation on control.reset
            this.isInvalid.emit({ hasError: false, errorMessage: '' });
            this._controlMessage = '';
          }
        });
    }
  }

  _fg: UntypedFormGroup;
  @Input() set groupForm(fg: AbstractControl) {
    if (fg instanceof UntypedFormGroup) {
      this._fg = fg;
      if (fg && fg.validator) {
        if (this.validate) {
          this._fg.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
              if (this._fg.touched) {
                this._groupMessage = this.setMessage(this._fg);
              }
            });
        }
      }
    }
  }

  @Input() set validateNow(now: boolean) {
    if (this._fc instanceof EzFormControl && this.validate && now) {
      this._controlMessage = this.setMessage(this._fc);
    }
  }

  fieldLabel$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() set fieldLabel(label: string) {
    if (label?.length) {
      this.fieldLabel$.next(label.trim());
    }
  }

  _fieldMessage = '';
  @Input() set fieldMessage(message: string) {
    this._fieldMessage = message;
  }

  // Placeholder properties
  _showAdvancedPlaceholder = false;
  @Input() set showAdvancedPlaceholder(show: boolean) {
    this._showAdvancedPlaceholder = show;
  }

  _placeholderVisible = true;
  @Input() set placeholderVisible(show: boolean) {
    this._placeholderVisible = show;
  }

  _placeholderText = '';
  @Input() set placeholderText(text: string) {
    this._placeholderText = text;
  }

  _linkText = '';
  @Input() set linkText(text: string) {
    this._linkText = text;
  }

  _hasLabel = '';
  @Input() set hasLabel(type: string) {
    this._hasLabel = type;
  }

  _keepTooltipOpenOnHover: boolean = false;
  @Input() set keepTooltipOpenOnHover(keepTooltipOpen: boolean) {
    this._keepTooltipOpenOnHover = keepTooltipOpen;
  }

  _enableTooltipWithCheckboxAlignment: boolean = false;
  @Input() set enableTooltipWithCheckboxAlignment(tooltipWithCheckboxAlignment: boolean) {
    this._enableTooltipWithCheckboxAlignment = tooltipWithCheckboxAlignment;
  }

  @Output() placeholderClicked: EventEmitter<any> = new EventEmitter();
  @Output() linkClicked: EventEmitter<any> = new EventEmitter();

  @Output() isInvalid = new EventEmitter<EzFormControlTemplateError>();

  _groupMessage = '';
  _controlMessage = '';

  notificationErrorVisible: boolean = false;

  cypressName: string = '';

  private validationMessages = ezValidationMessages;

  constructor(private notificationService: EzNotificationService) {
  }

  ngOnInit() {
    if (this._fc instanceof EzFormControl) {
      if (!this._fc.visible) {
        this.visible = false;
      }
    }

    if (this.customValidationMessages) {
      this.validationMessages = {
        ...this.validationMessages,
        ...this.customValidationMessages
      };
    }
  }

  private setMessage(c: AbstractControl): string {
    let outputMessage = '';
    let hasError: boolean = false;
    let isAsyncValidatorError: boolean = false;

    if (c instanceof EzFormControl) {
      if (c.asyncValidators instanceof EzAsyncValidator) {
        let ezAsyncValidator: EzAsyncValidator = c.asyncValidators as EzAsyncValidator;
        //check if the errors collection contains this async validator's error
        if (c.errors && Object.entries(c.errors).filter(e => e[0] === ezAsyncValidator.errorType).length) {
          hasError = true;
          isAsyncValidatorError = true;
          if (ezAsyncValidator.showErrorAsNotification) {
            if (!this.notificationErrorVisible) {
              this.notificationErrorVisible = true;
              this.notificationService.sendFailNotification({
                message: ezAsyncValidator.errorMessage,
                title: '',
                type: NotificationType.error
              });
            }
            outputMessage += ezAsyncValidator.errorMessage;
          }
        } else {
          isAsyncValidatorError = false;
          if (this.notificationErrorVisible) {
            this.notificationErrorVisible = false;
            this.notificationService.clearNotification();
          }
        }
      }
    }
    if (c.errors && !isAsyncValidatorError) {
      outputMessage += this.buildValidationErrorMessage(c.errors);
      hasError = true;
    }

    this.isInvalid.emit({ hasError: hasError, errorMessage: outputMessage });
    return outputMessage;
  }

  private buildValidationErrorMessage(errors: Record<string, boolean>): string {
    let outputMessage: string = '';
    Object.entries(errors).forEach((err: ValidationErrors) => {
      outputMessage += outputMessage.length > 0 ? ' ' : '';

      const errorType: string = err[0];
      let errorFieldLabel: string = this.customErrorFieldLabel ? this.customErrorFieldLabel : this.fieldLabel$.value;
      if (errorFieldLabel?.endsWith(':')) {
        errorFieldLabel = errorFieldLabel.slice(0, -1);
      }
      switch (errorType) {
        case 'maxlength':
          if (this.validationMessages[errorType] === this.customValidationMessages?.maxlength) {
            outputMessage += this.validationMessages[errorType]
          } else {
            outputMessage += errorFieldLabel + this.validationMessages[errorType] + err[1].requiredLength + '.';
          }
          break;
        case 'min':
          outputMessage += errorFieldLabel + this.validationMessages[errorType] + (this.isPercent ? err[1].min * 100 : err[1].min) + '.';
          break;
        case 'max':
          outputMessage += errorFieldLabel + this.validationMessages[errorType] + (this.isPercent ? err[1].max * 100 : err[1].max) + '.';
          break;
        // TODO: once style guide validation apply remove this temps to min&max age
        case 'minAge':
          outputMessage += 'This field ' + this.validationMessages['min'] + err[1] + '.';
          break;
        case 'maxAge':
          outputMessage += 'This field ' + this.validationMessages['max'] + err[1] + '.';
          break;
        //
        case 'clientCannotBeReferer':
          outputMessage += ResourcesProvider.rks.ClientCannotBeReferer;
          break;
        case 'dateLessThan':
        case 'dayLessThan':
        case 'timeLessThan':
          outputMessage += errorFieldLabel + this.validationMessages[errorType];
          if (this.validationCompareFieldName) {
            outputMessage += this.validationCompareFieldName + '.';
          };
          break;
        case 'dateWithinRange':
          outputMessage += ResourcesProvider.rks.DateWithinRangeValidationErrorMessage(err[1].unit, err[1].value);
          break;
        case 'invalidStartDate':
          outputMessage += ResourcesProvider.rks.StartDateCannotBeAfterTheEndDate;
          break;
        case 'invalidEndDate':
          outputMessage += ResourcesProvider.rks.EndDateCannotBeBeforeTheStartDate;
          break;
        case 'invalidStartTime':
          outputMessage += ResourcesProvider.rks.StartTimeCannotBeAfterEndTime;
          break;
        case 'invalidEndTime':
          outputMessage += ResourcesProvider.rks.EndTimeCannotBeBeforeStartTime;
          break;
        case 'invalidDateTime':
          outputMessage += this.validationMessages[errorType];
          break;
        case 'intervalMismatch':
          outputMessage += ResourcesProvider.rks.IntervalMismatchValidationMessage(err[1]);
          break;
        case 'numberLessThan':
          outputMessage += errorFieldLabel + this.validationMessages[errorType];
          if (this.validationCompareFieldName) {
            outputMessage += this.validationCompareFieldName;
          }
          break;
        case 'ageLessThen':
          outputMessage += this.validationMessages[errorType];
          break;
        case 'email':
        case 'termLengthQuantityValid':
        case 'termQuantityValid':
          outputMessage += this.validationMessages[errorType];
          break;
        case 'zeroFeeDoesNotAllow':
        case 'zeroAndNegativeNumberDoesNotAllow':
          const fieldName = err[1][0] ? err[1][0] : errorFieldLabel;
          outputMessage += this.validationMessages[errorType](fieldName);
          break;
        case 'daysExceedNorm':
          const maxDays = err[1];
          outputMessage += errorFieldLabel + this.validationMessages[errorType](maxDays);
          break;
        case 'renewalDateValid':
          outputMessage += ResourcesProvider.rks.RenewalPeriodLongerThanContract;
          break;
        case 'reservationNoResourcesSelected':
          outputMessage += ResourcesProvider.rks.ReservationNoResourcesSelectedWarning;
          break;
        case 'atLeastOneFieldRequired':
          outputMessage += ResourcesProvider.rks.AtLeastOneFieldIsRequired;
          break;
        case 'reservationClassSizeLessThanTotalRegisteredClientsNumber':
          outputMessage += ResourcesProvider.rks.ReservationClassSizeLessThanTotalRegisteredClientsNumberWarning;
          break;
        case 'renewalDiscountGreaterThanFee':
          const discount = err[1][0];
          const perTermFee = err[1][1];
          outputMessage += errorFieldLabel + this.validationMessages[errorType](discount, perTermFee);
          break;
        case 'subform':
          outputMessage = '';
          break;
        case 'required':
          if (this.validationMessages[errorType] === this.customValidationMessages?.required) {
            outputMessage += this.validationMessages[errorType]
          } else {
            outputMessage += errorFieldLabel + this.validationMessages[errorType];
          }
          break;
        case 'emailsListExceedNorm':
          const maxEmails = err[1];
          outputMessage += this.validationMessages[errorType](maxEmails);
          break;
        case 'validateDuplicateEmailTemplateName':
          outputMessage += this.validationMessages[errorType];
          break;
        case 'requiredTrue':
        case 'numberValid':
        case 'integerValid':
        case 'currencyValid':
        case 'specialCharsValid':
        case 'requireCheckboxesToBeChecked':
        case 'creditCardNumberValid':
        case 'pattern':
        case 'whitespace':
        case 'oneOptionMustBeSelected':
        default:
          outputMessage += errorFieldLabel + this.validationMessages[errorType];
          break;
      }
    });
    return outputMessage;
  }

  placeholderClick() {
    this.placeholderClicked.emit();
  }

  linkClick() {
    this.linkClicked.emit();
  }

}
