import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  forwardRef
} from '@angular/core';
import { EzLocaleHelper } from '@ezuiaws/ez-packages/ez-localization';
import { getCurrencySymbol } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { EzFormControlTemplateComponent } from '../ez-form-control-template/ez-form-control-template.component';
import { EzFormControlTemplateError } from '../ez-form-control-template/ez-form-control-template-error';
import { getCypressFieldName } from '../../helpers';

@Component({
  selector: 'ez-form-control-input',
  templateUrl: './ez-form-control-input.component.html',
  styleUrls: ['./ez-form-control-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EzFormControlInputComponent),
      multi: true
    }
  ]
})
export class EzFormControlInputComponent<T> implements OnInit, ControlValueAccessor {

  @ViewChild('txtInput', { static: false }) txtInput: ElementRef<HTMLElement>;
  @ViewChild('txtArea', { static: false }) txtArea: ElementRef<HTMLElement>;
  @ViewChild('decimalInput', { static: false }) decimalInput: ElementRef<HTMLElement>;
  @ViewChild('ezFormControlTemplate', { static: true }) ezFormControlTemplate: EzFormControlTemplateComponent;

  @Input() control: AbstractControl;
  @Input() groupForm: UntypedFormGroup;
  @Input() useDefaultInput = true;
  @Input() showLabel = true;
  @Input() showIconRight = false;
  @Input() showIconLeft = false;
  @Input() isTextArea = false;
  @Input() isNumeric = false;
  @Input() isWholeNumber = false;
  @Input() isOnlyPositiveNumber = false;
  @Input() isCurrency = false;
  @Input() isPassword = false;
  @Input() isPercent = false;
  @Input() isDisabled = false;
  @Input() showPreAddon = false;
  @Input() showPostAddon = false;
  @Input() rows = 2;
  @Input() toolTipHtml: InnerHTML = null;
  @Input() autocompleteAttribute: string = null;
  @Input() validationCompareFieldName: string;
  @Input() validateOnKeyStroke: boolean = true;
  @Input() validateNow: boolean = false;
  @Input() fieldLabel: string = '';
  @Input() inputIconClass: string = '';
  @Input() lighteningLabelOnDisabled: boolean = true;
  @Input() customValidationMessages: Record<string, string> | null = null;

  _showAdvancedPlaceholder = false;
  @Input() set showAdvancedPlaceholder(show: boolean) {
    this._showAdvancedPlaceholder = show;
  }

  _placeholderVisible = true;
  @Input() set placeholderVisible(show: boolean) {
    this._placeholderVisible = show;
  }

  _placeholderText = '';
  @Input() set placeholderText(text: string) {
    this._placeholderText = text;
  }

  _linkText = '';
  @Input() set linkText(text: string) {
    this._linkText = text;
  }

  _keepTooltipOpenOnHover: boolean = false;
  @Input() set keepTooltipOpenOnHover(keepTooltipOpen: boolean) {
    this._keepTooltipOpenOnHover = keepTooltipOpen;
  }

  _showBottomMargin: boolean = true;
  @Input() set showBottomMargin(showMargin: boolean) {
    this._showBottomMargin = showMargin;
  }

  _warningMessageText: string = '';
  @Input() set warningMessageText(warningText: string) {
    this._warningMessageText = warningText;
  }

  _boldLabel: boolean = false;
  @Input() set boldLabel(boldLabel: boolean) {
    this._boldLabel = boldLabel;
  }

  @Input() set showWarningMessage(showWarning: boolean) {
    this.showWarningMessage$.next(showWarning);
  }

  @Output() placeholderClicked: EventEmitter<any> = new EventEmitter();
  @Output() linkClicked: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string | number> = new EventEmitter();
  @Output() onFocusOut: EventEmitter<string | number> = new EventEmitter();

  showWarningMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  inputIsInvalid = false;
  inputValue: string | number = '';
  cypressName: string = '';

  //Unfortunately, we have to hard-code en-US here instead of using the current locale
  //because <p-inputNumber> uses Javascript formatting functions instead of angular functions like we use in LocationCurrencyPipe.
  //This means all currencies will be formatted like "¤ #,##0.00", where ¤ is the correct currency symbol.
  //This will become an issue once we need currencies like id-ID / IDR (Indonesian rupiah)
  //When we get to it, possible solution might be to use the currencyDisplay property of <p-inputNumber>
  // locale: string = EzLocaleHelper.locale;
  locale: string = 'en-US';
  currencyCode: string = EzLocaleHelper.currencyCode;
  localeCultureCurrencyCombo: string = EzLocaleHelper.localeCultureCurrencyCombo;
  currencySymbolNarrow: string = '';

  onChange: any = () => { };
  onTouched: any = () => { };

  ngOnInit(): void {
    this.cypressName = getCypressFieldName(this.control);
    this.currencySymbolNarrow = getCurrencySymbol(this.currencyCode, 'narrow', this.localeCultureCurrencyCombo);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChangeEvent(value: string | number) {
    this.onTouched();

    const convertedInputValue: string | number = this.getInputConvertedValue(value);
    this.showWarningMessage$.next(false);

    this.valueChanged.emit(convertedInputValue);
    this.writeValue(convertedInputValue);
  }

  onFocusOutEvent(value: string | number) {
    if (this._showAdvancedPlaceholder && !value) {
      this._placeholderVisible = true;
    };

    this.onFocusOut.emit(value);
  }

  onFocusEvent() {
    if (this._showAdvancedPlaceholder) {
      this._placeholderVisible = false;
    }
  }

  writeValue(value: string | number): void {
    this.inputValue = this.getInputConvertedValue(value, this.isPercent);
    this.onChange(value);
  }

  getInputConvertedValue(inputValue: string | number, toPercent: boolean = false): string | number | null {
    let convertedInputValue: string | number | null = null;
    if (
      inputValue !== null &&
      typeof inputValue !== 'undefined' &&
      ((this.isNumeric && inputValue !== '') || (this.isPercent && inputValue !== '') || (!this.isPercent && !this.isNumeric))
    ) {
      if (this.isNumeric && !isNaN(+inputValue)) {
        convertedInputValue = Number(inputValue);
      } else if (this.isPercent && !isNaN(Number(inputValue))) {
        if (toPercent) {
          const valueInPercent: number = Number(inputValue) * 100;
          const valueInPercentWithoutFractionalPart: number = Math.trunc(valueInPercent);
          const fractionalPart: number = valueInPercent - valueInPercentWithoutFractionalPart;
          convertedInputValue = fractionalPart > 0.01 ? Number(valueInPercent).toFixed(2) : valueInPercentWithoutFractionalPart;
        } else {
          convertedInputValue = Number(Number(inputValue).toFixed(2)) / 100;
        }
      } else if (typeof inputValue === 'string') {
        convertedInputValue = inputValue.trim();
      } else {
        convertedInputValue = inputValue;
      }
    }

    return convertedInputValue;
  }

  setInputIsInvalid(event: EzFormControlTemplateError) {
    this.inputIsInvalid = event.hasError;
  }

  placeholderClick() {
    if (this.isTextArea) {
      this.txtArea.nativeElement.focus();
    } else if (this.isCurrency) {
      this.decimalInput.nativeElement.focus();
    } else {
      this.txtInput.nativeElement.focus();
    }
    if (this._showAdvancedPlaceholder) {
      this._placeholderVisible = false;
    }

    this.placeholderClicked.emit();
  }

  linkClick() {
    this.linkClicked.emit();
  }
}
