<ez-form-control-template #ezFormControlTemplate
  [control]="control"
  [groupForm]="groupForm"
  [validateNow]="validateNow"
  [validationCompareFieldName]="validationCompareFieldName"
  [showAdvancedPlaceholder]="_showAdvancedPlaceholder"
  [placeholderText]="_placeholderText"
  [placeholderVisible]="_placeholderVisible"
  [keepTooltipOpenOnHover]="_keepTooltipOpenOnHover"
  [linkText]="_linkText"
  [toolTipHtml]="toolTipHtml"
  [showLabel]="showLabel"
  [fieldLabel]="fieldLabel"
  [isPercent]="isPercent"
  [isTextArea]="isTextArea"
  [boldLabel]="_boldLabel"
  [lighteningLabelOnDisabled] ='lighteningLabelOnDisabled'
  [customValidationMessages]="customValidationMessages"
  [showBottomMargin]="_showBottomMargin"
  [showWarningMessage]="showWarningMessage$ | async"
  [warningMessageText]="_warningMessageText"
	(isInvalid)="setInputIsInvalid($event)"
  (placeholderClicked)="placeholderClick()"
  (linkClicked)="linkClick()">
  <ng-content select="[preHtml]"></ng-content>

  <div class="p-inputgroup app-control"
    [ngClass]="{
      'p-input-icon-right': showIconRight,
      'p-input-icon-left': showIconLeft
    }">
    <ng-container *ngIf='showPreAddon'>
      <span class="p-inputgroup-addon">
        <ng-container *ngIf='isPercent'>
          <i class="pi pi-percentage"></i>
        </ng-container>
        <ng-container *ngIf='isCurrency'>{{currencySymbolNarrow}}</ng-container>
        <ng-content select="[preAddon]"></ng-content>
      </span>
    </ng-container>

    <ng-container *ngIf="useDefaultInput; else customInput">
      <ng-container *ngIf="!isTextArea && !isCurrency &&!isWholeNumber">

        <i *ngIf='showIconRight || showIconLeft'
          class="pi"
          [ngClass]='inputIconClass'>
        </i>

        <input #txtInput
          autocompleteOff
          inputWidthByPlaceholder
          type="{{isPassword ? 'password' : 'text'}}"
          class="form-control"
					[ngClass]="{'d-none': isTextArea === true}"
          [attr.autocomplete]="autocompleteAttribute"
          [attr.disabled]="isDisabled ? '' : null"
          [attr.data-cy]="cypressName + '-input'"
          [value]="inputValue"
          [placeholder]="_placeholderText"
          (input)="onChangeEvent(txtInput.value)"
          (focusout)="onFocusOutEvent(txtInput.value)"
          (focus)="onFocusEvent()">
      </ng-container>
      <ng-container *ngIf="isTextArea">
        <textarea #txtArea
          p-inputtextarea
          autocompleteOff
          [rows]="rows"
          class="form-control"
          [attr.data-cy]="cypressName + '-input'"
          [attr.disabled]="isDisabled ? '' : null"
          [value]="inputValue"
          [placeholder]="_placeholderText"
          (input)="onChangeEvent(txtArea.value)"
          (focusout)="onFocusOutEvent(txtArea.value)"
          (focus)="onFocusEvent()">
        </textarea>
      </ng-container>
      <ng-container *ngIf='isCurrency || isWholeNumber'>
        <p-inputNumber #decimalInput
          autocompleteOff
          mode="decimal"
					[ngClass]="{'w-100': true}"
          [ngModel]="inputValue"
          [locale]="locale"
          [disabled]="isDisabled"
          [attr.data-cy]="cypressName + '-input'"
          [min]="isOnlyPositiveNumber ? 0 : null"
          [minFractionDigits]="isWholeNumber ? 0 : isCurrency ? 2 : null"
          [maxFractionDigits]="isWholeNumber ? 0 : isCurrency ? 2 : null"
          [placeholder]="_placeholderText"
          (onInput)="onChangeEvent($event.value)"
          (onBlur)="onFocusOutEvent(decimalInput.value)"
          (onFocus)="onFocusEvent()">
        </p-inputNumber>
      </ng-container>
    </ng-container>

    <ng-template #customInput>
      <ng-content></ng-content>
    </ng-template>

    <ng-container *ngIf='showPostAddon'>
      <ng-content select="[postAddon]"></ng-content>
    </ng-container>

    <ng-container #panelTip>
      <ng-content select="[panelTip]"></ng-content>
    </ng-container>

  </div>
  <ng-content select="[postHtml]"></ng-content>
</ez-form-control-template>
