<ng-container *ngIf="_showDropDown">
  <div #container class="custom-multiselect-container">
    <ez-form-control-template [control]="_control"
      [showAdvancedPlaceholder]="true"
      [placeholderVisible]="_placeholderVisible"
      [placeholderText]="_dropDownPlaceholderText"
      [linkText]="_dropDownLinkText"
      [disabled]='isDisabled'
      [toolTipHtml]="toolTipHtml"
      [customValidationMessages]="customValidationMessages"
      [boldLabel]="_boldLabel"
      [showBottomMargin]='showBottomMargin'
      (isInvalid)="inputIsInvalid($event)">
      <p-multiSelect #dropdownMultiselect
        detectDropdownPanelShowDirection
        styleClass='w-100'
        display="chip"
        [elementType]='"multiselect"'
        [placeholder]="simplePlaceholder"
        [attr.data-cy]="cypressName"
        [options]="_options"
        [disabled]='isDisabled'
        [ngModel]="selectedValue"
        [resetFilterOnHide]='true'
        [emptyMessage]="emptyMessage"
        [emptyFilterMessage]="emptyFilterMessage"
        [addCloseButton]="isMobileDevice$ | async"
        [overlayOptions]="{appendTo: 'body', styleClass: 'ez-select-overlay-panel ' + ((isMobileDevice$ | async) ? 'full-screen-dropdown multiselect' : '')}"	
        (onPanelShow)='toggleControlLabelAppearanceOnMobile()'
        (onPanelHide)='onPanelHide()'
        (onChange)="dropdownChange($event.value)">
        <ng-template let-options pTemplate="filter">
          <ng-container *ngIf="isMobileDevice$ | async">
            <div class="control-label custom-form-group-label full-screen-label">
              {{controlLabel}}
            </div>
          </ng-container>
          <ez-checkbox [selected]='isAllSelected()'
            (click)='selectAll($event)'>
          </ez-checkbox>
          <input pInputText
            [(ngModel)]="customFilter"
            [placeholder]="_searchPlaceHolder"
            (ngModelChange)="applyCustomFilter()">
          <ng-container *ngIf="(searchTerm$ | async).length && customFilter; else searchIcon">
            <span class="clearIcon"
              (click)="clearFilter()">
            </span>
          </ng-container>
          <ng-template #searchIcon>
            <span class="searchIcon"></span>
          </ng-template>
        </ng-template>

        <ng-template let-option pTemplate="item">
          <div appHighlightSearch="searchTerm$ | async"
            [searchTerm]="searchTerm$ | async">
            {{ option.label }}
          </div>
        </ng-template>

      </p-multiSelect>
    </ez-form-control-template>
    <ng-container *ngIf="(overflowCount$ | async) > 0">
      <div class="custom-multiselect-badge">+{{ overflowCount$ | async }}</div>
    </ng-container>

  </div>
</ng-container>
<ng-container *ngIf="!_showDropDown">
  <ng-content></ng-content>
</ng-container>
